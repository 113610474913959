import React, { useEffect, useState } from "react";
import { Button, Form, Image, Modal, ModalActions, ModalContent, ModalHeader, Popup } from "semantic-ui-react";
import ErrorLabel from '../../components/error-label/ErrorLabel';
import eyeIcon from '../../assets/images/ui-show-pass.svg';
import { profileActions } from "../../features/profile";
import { ProfileSaveAttemptModel } from "../../features/profile/actions";
import { IUser } from "../../models";

function checkUpperCase(character: string) {
  return (/[A-Z]/.test(character)) ? true : false;
}

function checkLowerCase(character: string) {
  return (/[a-z]/.test(character)) ? true : false;
}

function checkNumberChar(character: string) {
  return (/[0-9]/.test(character)) ? true : false;
}

function checkSpecialChar(character: string) {
  return (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(character)) ? true : false;
}

export function ChangePassword({
  profileSaveAttempt,
  user,
}: {
  profileSaveAttempt: typeof profileActions.profileSaveAttempt,
  user: IUser,
}) {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [errorPassword, setErrorPassword] = useState('');
  const [errorPasswordConfirm, setErrorPasswordConfirm] = useState('');

  const [show, setShow] = useState(false)

  useEffect(() => {
    onReset();
  }, [show])

  function onChangePassword(e: any) {
    const value = e.target.value
    setPassword(value)

    const isCheckUpperCase = checkUpperCase(value)
    const isCheckLowerCase = checkLowerCase(value)
    const isCheckSpecialChar = checkSpecialChar(value)
    const ischeckNumberChar = checkNumberChar(value)

    switch (false) {
      case isCheckLowerCase:
        setErrorPassword('The password must contain at least 1 lowercase character.')
        break;
      case isCheckUpperCase:
        setErrorPassword('The password must contain at least 1 uppercase character.')
        break;
      case isCheckSpecialChar:
        setErrorPassword('The password must contain at least 1 special character.')
        break;
      case ischeckNumberChar:
        setErrorPassword('The password must contain at least 1 number.')
        break;
      case value.length >= 8:
        setErrorPassword('The password must be at least 8 symbols long.')
        break;
      default:
        setErrorPassword('')
        break;
    }

    setErrorPasswordConfirm(passwordConfirm && passwordConfirm !== value ? 'Passwords do not match.' : '')
  }

  function onChangePasswordConfirm(e: any) {
    const value = e.target.value
    setPasswordConfirm(value)

    setErrorPasswordConfirm(value !== password ? 'Passwords do not match.' : '')
  }

  async function onSave() {
    const avatar = user.avatar ? (new URL(user.avatar)).pathname : '';

    const data: ProfileSaveAttemptModel = {
      password,
      passwordConfirm,
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      avatar,
    }

    await profileSaveAttempt(data);

    setShow(false)
  }

  function onReset() {
    setPassword('')
    setPasswordConfirm('')
    setShowPassword(false)
    setShowPasswordConfirm(false)
    setErrorPassword('')
    setErrorPasswordConfirm('')
  }

  return (
    <Modal
      size="tiny"
      onClose={() => setShow(false)}
      onOpen={() => setShow(true)}
      open={show}
      trigger={<Button type="button">Change Password</Button>}
    >
      <ModalHeader>Change Password</ModalHeader>
      <ModalContent>
        <div>
          <div className="input-field-container">
            <div className="input-field-label">Password</div>
            <div className="field-input-container">
              <Form.Input
                className="child-w-full"
                name="password"
                icon={
                  <Popup
                    trigger={
                      <i
                        className={`icon link custom-icon`}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <Image
                          src={eyeIcon}
                          className={
                            showPassword ? 'show-pass-icon' : 'hide-pass-icon'
                          }
                        />
                      </i>
                    }
                    size="mini"
                    basic={true}
                    content={showPassword ? 'Hide Password' : 'View Password'}
                  />
                }
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={onChangePassword}
              />
              {errorPassword && <ErrorLabel style={{ display: 'block' }}>{errorPassword}</ErrorLabel>}
            </div>
          </div>
          <div className="input-field-container">
            <div className="input-field-label">Confirm Password</div>
            <div className="field-input-container">
              <Form.Input
                className="child-w-full"
                name="passwordConfirm"
                type={showPasswordConfirm ? 'text' : 'password'}
                icon={
                  <Popup
                    trigger={
                      <i
                        className={`icon link custom-icon`}
                        onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                      >
                        <Image
                          src={eyeIcon}
                          className={
                            showPasswordConfirm ? 'show-pass-icon' : 'hide-pass-icon'
                          }
                        />
                      </i>
                    }
                    size="mini"
                    basic={true}
                    content={showPasswordConfirm ? 'Hide Password' : 'View Password'}
                  />
                }
                value={passwordConfirm}
                onChange={onChangePasswordConfirm}
              />
              {errorPasswordConfirm && <ErrorLabel style={{ display: 'block' }}>{errorPasswordConfirm}</ErrorLabel>}
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalActions>
        <Button style={{ width: '120px' }} primary={false} onClick={() => setShow(false)}>
          Cancel
        </Button>
        <Button style={{ width: '120px' }} primary={true} disabled={Boolean(errorPassword || errorPasswordConfirm) || !password || !passwordConfirm} onClick={onSave}>
          Save
        </Button>
      </ModalActions>
    </Modal>

  )
}

export default ChangePassword;