import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Image, Pagination, Popup, Table } from 'semantic-ui-react';
import downloadIcon from '../../assets/images/Blue/download.svg';
import noAvatar from '../../assets/images/no-avatar.svg';
import downloadIconW from '../../assets/images/White/download.svg';
import { IUser } from '../../models';
import { AdjusterUser } from '../../models/adjuster';
import services from '../../services';
import UploadFinance from '../register-layout/UploadFinance';
import FinanceFilter from '../table-filters/FinanceFilter';
import CustomIconicButton from './../adjuster-list/CustomIconicButton';
import { LoadingSpiner } from './../loading-spinner/LoadingSpinner';
import { NoResults } from './../no-results-placeholder/NoResults';

import * as helper from '../../utils/helper';

interface Props {
  download: (key: any) => void;
  getSubmitRef: (ref: any) => void;
  admin: IUser;
  user: AdjusterUser;
}

interface State {
  finances: object[];
  initialFinances: object[];
  filter: object;
  totalFinances: number;
  showFilters: boolean;
  filterState: any;
  activeId: number;
  activePage: number;
  page: number;
  loadingExportList: Boolean;
}

const initialFilter = {
  from: '',
  to: '',
  name: '',
  uploaderId: '',
  sort: 'finance.createdAt,desc',
};

class ProfileFinances extends React.Component<Props, State> {
  public state = {
    finances: [],
    initialFinances: [],
    uploadersArray: [],
    filter: {},
    totalFinances: 0,
    showFilters: false,
    filterState: { sort: 'finance.createdAt,desc' },
    activeId: 0,
    activePage: 1,
    page: 0,
    loadingExportList: false,
  };

  public componentDidMount = async () => {
    this.listFinances({ pageSize: 20 });
  };

  public listFinances = async (params?: any) => {
    if (params) {
      const dataModel = Object.assign({}, params);
      for (const key in dataModel) {
        if (dataModel[key] === '') {
          dataModel[key] = undefined;
        }
      }
      if (dataModel.from && !dataModel.to) {
        dataModel.to = moment()
          .add(1, 'd')
          .format();
      }
      dataModel.pageSize = 20;
      params = dataModel;
    }
    const response = await services.api.admin.getFilteredFinances({
      adjusterId: this.props.user.id,
      ...params,
    });
    if (response.isSuccess) {
      await services.api.finance.getFinanceUploaderAvatarUrl(response.data.list);
      this.setState({ finances: [], totalFinances: response.data.count }, () =>
        this.setState({ finances: response.data.list })
      );
    }
    const response2 = await services.api.admin.getInitialFinances({
      adjusterId: this.props.user.id,
      page: params ? params.page : 0,
      pageSize: 20,
    });
    if (response2.isSuccess) {
      this.setState({ initialFinances: response2.data.list });
    }
  };

  public handleExport = async () => {
    const { filterState } = this.state;

    const dataModel = Object.assign({}, filterState);
    for (const key in dataModel) {
      if (dataModel[key] === '') {
        dataModel[key] = undefined;
      }
    }
    const params = dataModel;

    this.setState({ loadingExportList: true });
    const response = await services.api.finance.exportFinanceList({
      adjusterId: this.props.user.id,
      ...params,
    });
    this.setState({ loadingExportList: false });
    if (response.error) {
      console.log(response);
      toast.dismiss();
      toast.error(response.error ? response.error : `Error ${response.status}`);
    }
  };

  public handleFilterReset = () => {
    this.setState(
      {
        filterState: {
          ...initialFilter,
          page: 0,
        },
        activePage: 1,
        finances: [],
      },
      () => this.listFinances(this.state.filterState)
    );
  };

  public handleFilterChange = (data: any) => {
    this.setState(
      {
        filterState: {
          ...this.state.filterState,
          ...data,
          page: 0,
        },
        activePage: 1,
        finances: [],
      },
      () => this.listFinances(this.state.filterState)
    );
  };

  public handlePagination = (e: any, data: any) => {
    this.changePageAndRefresh(data.activePage);
  };

  public render() {
    const { finances, showFilters, activeId, totalFinances, initialFinances } = this.state;
    const totalPages = helper.getTotalPage(totalFinances, 20);
    return (
      <div className="admin-adjusters-finances-page">
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Popup
            trigger={
              <Button
                loading={this.state.loadingExportList}
                disabled={finances.length === 0}
                primary={true}
                className="adjuster-action-button"
                onClick={this.handleExport}
              >
                <Image src={downloadIconW} />
              </Button>
            }
            basic={true}
            size="mini"
            content={'Export list'}
          />
          <Button
            primary={true}
            style={{ height: '32px' }}
            onClick={() => this.setState({ showFilters: !this.state.showFilters })}
          >
            Filter
          </Button>
          {this.props.admin.role !== 'Read Only Office Admin' && (
            <UploadFinance updateList={this.listFinances} user={this.props.user} />
          )}
        </div>
        {showFilters && (
          <FinanceFilter
            filterLogs={this.handleFilterChange}
            filter={this.state.filterState}
            reset={this.handleFilterReset}
            finances={initialFinances}
          />
        )}
        <LoadingSpiner area="finance-adjuster-list" />
        {finances.length === 0 ? (
          <div className="no-results-finances">
            <NoResults />
          </div>
        ) : (
          <Table
            compact={true}
            striped={true}
            className="data-table adjusters-table logs-table finance-table-admin"
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="table-date">Date</Table.HeaderCell>
                <Table.HeaderCell id="table-email">File Name</Table.HeaderCell>
                <Table.HeaderCell className="table-user">Uploaded By</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {finances.map((finance: any, i: number) => (
                <Table.Row key={finance.id}>
                  <Table.Cell className="table-date">
                    {moment(finance.createdAt).format('MM/DD/YYYY, h:mA ')}
                  </Table.Cell>
                  <Table.Cell className="table-email" collapsing={true}>
                    {finance.file.name}
                  </Table.Cell>
                  <Table.Cell className="table-user">
                    <Image
                      circular={true}
                      src={finance.uploader.avatar ? finance.uploader.avatar : noAvatar}
                      avatar={true}
                    />
                    {finance.uploader.firstName} {finance.uploader.lastName}
                  </Table.Cell>
                  <Table.Cell style={{ position: 'relative' }} className="actions-table-row">
                    <div className="finance-uploader-avatar">
                      <CustomIconicButton
                        onPress={() =>
                          this.setState({ activeId: i }, () =>
                            this.props.download(finances[activeId].file)
                          )
                        }
                        popup={'Download'}
                        image={downloadIcon}
                      />
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
        {totalPages > 1 && (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Pagination
              totalPages={totalPages}
              boundaryRange={1}
              activePage={this.state.activePage}
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              prevItem={
                this.state.activePage === 1
                  ? false
                  : {
                      'aria-label': 'Previous item',
                      content: 'PREVIOUS',
                    }
              }
              nextItem={
                this.state.activePage === totalPages
                  ? false
                  : {
                      'aria-label': 'Next item',

                      content: 'NEXT',
                    }
              }
              siblingRange={1}
              onPageChange={this.handlePagination}
            />
          </div>
        )}
      </div>
    );
  }

  private changePageAndRefresh(page: number) {
    this.setState(
      {
        filterState: {
          ...this.state.filterState,
          page: page - 1,
        },
        activePage: page,
      },
      () => this.listFinances(this.state.filterState)
    );
  }
}

export default ProfileFinances;
