import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Button, Image, Dropdown, Form } from 'semantic-ui-react';
import arrowIcon from '../../assets/images/ui-dropdown.svg';
import services from '../../services';
import ErrorLabel from '../error-label/ErrorLabel';
import classNames from 'classnames';
import { toast } from 'react-toastify';

const items = [
  { label: 'SMS', key: 'twilio' },
  { label: 'Personal Email', key: 'email' },
  { label: 'Compass Email', key: 'compassEmail' },
]

function useInputState<T>(defaultValue: T) {
  const [value, setValue] = useState(defaultValue);
  const [checkError, setCheckError] = useState(false);

  function onChange(value: T) {
    setValue(value)
    setCheckError(true)
  }

  function reset(newDefaultValue?: T) {
    setValue(newDefaultValue || defaultValue);
    setCheckError(false);
  }

  function validate(value: boolean) {
    setCheckError(true)
    return value;
  }

  return { value, checkError, onChange, reset, validate }
}

function RSendMessage({
  adjuster,
  match,
  userId,
  user,
  updateData,
  standby,
  setMsgObj,
  showTwilioModal,
  setRoomPage
}: {
  adjuster: any,
  match: any;
  userId: any;
  user: any;
  updateData: () => void;
  standby: any;
  setMsgObj: (obj: object) => void;
  showTwilioModal: (show: boolean) => void;
  setRoomPage: (page: number) => void;
}) {
  const title = useInputState("")
  const text = useInputState("")
  const ccbcc = useInputState({
    cc: '',
    bcc: '',
  })
  const sendVia = useInputState({
    twilio: false,
    email: true,
    compassEmail: false,
  })

  const titleError = title.checkError && !title.value
  const textError = text.checkError && !text.value
  const sendViaError = sendVia.checkError && adjuster.firstName && !sendVia.value.twilio && !sendVia.value.email && !sendVia.value.compassEmail
  const ccbccError = ccbcc.checkError && !validateEmail()

  const sendViaValue: any = sendVia.value

  function validate() {
    const error = [
      title.validate(!title.value),
      text.validate(!text.value),
      sendVia.validate(adjuster.firstName && !sendVia.value.twilio && !sendVia.value.email && !sendVia.value.compassEmail),
      ccbcc.validate(!validateEmail()),
    ]

    return error.includes(true);
  }

  function onSendViaChange(e: ChangeEvent<HTMLInputElement>) {
    const id = e.currentTarget.id
    const value = e.currentTarget.checked

    sendVia.onChange({
      ...sendVia.value,
      [id]: value,
    })
  }

  function onCcBccChange(id: string, e: ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value

    ccbcc.onChange({
      ...ccbcc.value,
      [id]: value,
    })
  }

  const [templates, setTemplates] = useState<any[]>()
  const [templateOptions, setTemplateOptions] = useState<any[]>()
  const [showFullDescription, setShowFullDescription] = useState(false)
  const [templateId, setTemplateId] = useState<number>()
  const messagesEndBottom = useRef<HTMLDivElement>(null)

  function validateEmail() {
    return (ccbcc.value.cc || "").split(',').length + (ccbcc.value.bcc || "").split(',').length <= 20
  }

  async function init() {
    const response = await services.api.settings.getAllMsgTemplates();
    if (response.isSuccess) {
      const optionsArray = response.data.map((el: any) => {
        return { text: el.name, value: el.id };
      });

      setTemplateOptions(optionsArray)
      setTemplates(response.data)
    } else {
      console.log(response);
    }
  };

  function resetForm() {
    const activeTemplate = templates && templates.find((el: any) => el.id === templateId);

    title.reset((activeTemplate && activeTemplate.title) || '');
    text.reset((activeTemplate && activeTemplate.text) || '');
    ccbcc.reset();
    sendVia.reset();
    setRoomPage(0)
  };

  async function onSubmit() {
    if (validate()) return;

    const model = {
      title: title.value,
      text: text.value,
      cc: ccbcc.value.cc,
      bcc: ccbcc.value.bcc,
      provider: [
        sendVia.value.twilio && 'twilio',
        sendVia.value.email && 'email',
        sendVia.value.compassEmail && 'compassEmail',
      ].filter(Boolean)
    }

    console.log('model', model)

    const obj = Object.assign({}, model, {
      recipients: { adjusters: [userId], admins: [] },
    });
    if (match.params.sbid) {
      if (!standby.twilioNumber && sendVia.value.twilio) {
        setMsgObj(obj);
        showTwilioModal(true);
      } else {
        const response = await services.api.messages.sendGlobalMessage(
          obj,
          match.params.gpid,
          match.params.sbid
        );
        if (response.isSuccess) {
          resetForm();
          updateData();
          toast.dismiss();
          toast.success('The message to the standby recipient was sent successfully!');
        } else {
          toast.dismiss();
          toast.error(
            response.data.errors
              ? response.data.errors[0].msg
              : `Error ${response.status}`
          );
        }
      }
    } else {
      const responseGlobal = await services.api.messages.sendGlobalMessage(
        obj,
        match.params.gpid
      );
      if (responseGlobal.isSuccess) {
        resetForm();
        updateData();
        toast.dismiss();
        toast.success('The message was sent successfully!');
      } else {
        toast.dismiss();
        toast.error(
          responseGlobal.data.errors
            ? responseGlobal.data.errors[0].msg
            : `Error ${responseGlobal.status}`
        );
      }
    }
  }

  function onTemplateChange(e: any, data: any) {
    if (!templates) return;
    const activeTemplate = templates.find((el: any) => el.id === data.value);

    setTemplateId(data.value)
    title.onChange(activeTemplate.title)
    text.onChange(activeTemplate.text)
  };

  useEffect(() => {
    init();
  }, [])

  return (
    <>
      <div
        className={`send-message-container room-send-message ${
          showFullDescription ? 'active' : ''
        }`}
      >
        <div
          className="standby-description-toggle"
          onClick={() => {
            setShowFullDescription(!showFullDescription)
            setTimeout(() => {
              messagesEndBottom.current &&
              messagesEndBottom.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
              })
            }, 100);
          }}
        >
          Add Message
          <Image
            src={arrowIcon}
            className={
              showFullDescription
                ? 'standby-description-details-arrow active'
                : 'standby-description-details-arrow'
            }
          />
        </div>
        <hr />
        <Form
          id="send-message-form"
          onSubmit={onSubmit}
        >
          <div className="send-message-form-container">
            <div className="input-field-container">
              <div className="input-field-label">Message Template</div>
              <div className="field-input-container">
                <div className='standby-dropdown'>
                  <Dropdown
                    name="template"
                    search={true}
                    selectOnBlur={false}
                    placeholder="Please Select"
                    className="standby-dropdown"
                    options={templateOptions}
                    onChange={onTemplateChange}
                  />
                </div>
              </div>
            </div>

            <div className="input-field-container">
              <div className="input-field-label">Title</div>
              <div className="field-input-container">
                <div className="form-input">
                  <input
                    name="title"
                    value={title.value}
                    onChange={(e) => title.onChange(e.target.value)}
                    maxLength={128}
                    placeholder="Enter please..."
                  />
                  {titleError && <ErrorLabel>
                    * Title is required field.
                  </ErrorLabel>}
                </div>
              </div>
            </div>

            <div className="input-field-container">
              <div className="input-field-label">Message</div>
              <div className="field-input-container">
                <textarea
                  name="text"
                  value={text.value}
                  onChange={(e) => text.onChange(e.target.value)}
                  maxLength={1024}
                  placeholder="Enter please..."
                  className="form-input"
                />
                 {textError && <ErrorLabel>
                  * Message is required field.
                </ErrorLabel>}
              </div>
            </div>
            <div
              className="input-field-container"
              style={{ display: !!adjuster.firstName ? 'flex' : 'none' }}
            >
              <div className="input-field-label">Send via</div>
              <div className="field-input-container">
                <div className="provider-group-container">
                  {items.map((el, index: number) => (
                    <div className={classNames("ui checkbox", {
                      checked: sendViaValue[el.key]
                    })} key={index} >
                      <input className="hidden" id={el.key} name={el.key} tabIndex={0} type="checkbox"
                        checked={sendViaValue[el.key]}
                        onChange={onSendViaChange}
                        disabled={
                          el.label === 'SMS' && standby && standby.closedAt
                            ? true
                            : false
                        }
                      />
                      <label htmlFor={el.key}>{el.label}</label>
                    </div>
                  ))}
                </div>
                {sendViaError && <ErrorLabel>
                  * Send via is required field.
                </ErrorLabel>}
              </div>
            </div>
            <div className="input-field-container">
              <div className={`input-field-label ${sendVia.value.email || sendVia.value.compassEmail ? '' : 'disabled'}`}>CC</div>
              <div className="field-input-container">
                <div className="form-input">
                  <input
                    name="cc"
                    disabled={!(sendVia.value.email || sendVia.value.compassEmail)}
                    placeholder="Enter please..."
                    value={ccbcc.value.cc}
                    onChange={onCcBccChange.bind(null, 'cc')}
                    className={classNames("ui input", {
                      disabled: !(sendVia.value.email || sendVia.value.compassEmail)
                    })}
                  />
                  {ccbccError && <ErrorLabel>
                  * Only 20 emails are allowed in CC + BCC.
                </ErrorLabel>}
                </div>
              </div>
            </div>
            <div className="input-field-container">
              <div className={`input-field-label ${sendVia.value.email || sendVia.value.compassEmail ? '' : 'disabled'}`}>BCC</div>
              <div className="field-input-container">
                <div className="form-input">
                  <input
                    name="bcc"
                    disabled={!(sendVia.value.email || sendVia.value.compassEmail)}
                    placeholder="Enter please..."
                    value={ccbcc.value.bcc}
                    onChange={onCcBccChange.bind(null, 'bcc')}
                    className={classNames("ui input", {
                      disabled: !(sendVia.value.email || sendVia.value.compassEmail)
                    })}
                  />
                  {ccbccError && <ErrorLabel>
                  * Only 20 emails are allowed in CC + BCC.
                </ErrorLabel>}
                </div>
              </div>
            </div>
            <div className="input-field-container">
              <div className={`input-field-label`} />
              <div className="field-input-container">
                <Button primary={true} style={{ width: '156px', height: '32px' }} type="submit">
                  Send
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div style={{ float: 'left', clear: 'both' }} ref={messagesEndBottom} />
    </>
  );
}

export default RSendMessage;
