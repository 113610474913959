import { trackPromise } from 'react-promise-tracker';
import * as aws from './aws';
import { request } from './base';

export const createAdmin = (model: {}) => {
  return trackPromise(
    request({
      method: 'post',
      url: 'admin/',
      data: {
        ...model,
      },
    }),
    'create-admin-modal-area'
  );
};

export const registerAdmin = (model: {}) => {
  return request({
    method: 'post',
    url: 'admin/register',
    data: {
      ...model,
    },
  });
};

export const editAdmin = (model: {}, id: string) => {
  return request({
    method: 'patch',
    url: `admin/${id}`,
    data: {
      ...model,
    },
  });
};

export const getAdminList = (params?: object, area?: string) => {
  return trackPromise(
    request({
      method: 'get',
      url: 'admin/list',
      params,
    }),
    area ? area : 'admin-list-table'
  );
};

export const changeStatus = (activeStatus: number, id: number) => {
  return request({
    method: 'patch',
    url: `admin/${id}`,
    data: {
      active_status: activeStatus,
    },
  });
};

export const resentActivationCode = (id: number) => {
  return request({
    method: 'get',
    url: `admin/${id}/resent-activation-code`,
  });
};

export const deleteAdmin = (id: number) => {
  return request({
    method: 'delete',
    url: `admin/${id}`,
  });
};

export const restoreAdmin = (id: number) => {
  return request({
    method: 'get',
    url: `admin/${id}/restore`,
  });
};

export const changeBulkStatus = (activeStatus: number, ids: number[]) => {
  return request({
    method: 'patch',
    url: `admin/status`,
    data: {
      activeStatus,
      ids,
    },
  });
};

export const deleteBulkAdmin = (ids: number[]) => {
  return request({
    method: 'delete',
    url: `admin/remove`,
    data: {
      ids,
    },
  });
};

export const fetchAdjusterData = async (id: number, statusTab?: boolean) => {
  const response = await trackPromise(
    request({
      method: 'get',
      url: `/adjuster/${id}`,
    }),
    'adjusters-profile-view'
  );
  if (response.isSuccess && !statusTab) {
    response.data.avatar = await aws.getDownloadFileUrl(response.data.avatar);
  }
  return response;
};

export const fetchAdjusterSkills = (id: number) => {
  return trackPromise(
    request({
      method: 'get',
      url: `/adjuster/professional-skills/${id}`,
    }),
    'adjusters-profile-view'
  );
};

export const fetchAdjusterDeployment = (id: number) => {
  return trackPromise(
    request({
      method: 'get',
      url: `/adjuster/${id}/deployments`,
    }),
    'adjusters-profile-view'
  );
};

export const getCustomEvents = (params: any) => {
  return trackPromise(
    request({
      method: 'get',
      url: '/event',
      params,
    }),
    'adjusters-profile-view'
  );
};

export const createEvent = (params: { name: string }) => {
  return trackPromise(
    request({
      method: 'post',
      url: '/event',
      data: { ...params },
    }),
    'adjusters-profile-view'
  );
};

export const updateEvent = (eventId: string, name: string) => {
  return trackPromise(
    request({
      method: 'patch',
      url: `/event/${eventId}`,
      data: { name },
    }),
    'adjusters-profile-view'
  );
};

export const deleteEvent = (eventId: string) => {
  return trackPromise(
    request({
      method: 'delete',
      url: `/event/${eventId}`,
    }),
    'adjusters-profile-view'
  );
};

export const deployAdjustersToEvent = (eventId: string, adjusters: object[]) => {
  return trackPromise(
    request({
      method: 'post',
      url: `/event/${eventId}/deploy`,
      data: { adjusters },
    }),
    'adjusters-profile-view'
  );
};

export const setAdjusterDeployment = (id: number, deployments: any) => {
  return trackPromise(
    request({
      method: 'post',
      url: `/adjuster/${id}/deploy`,
      data: { deployments },
    }),
    'adjusters-profile-view'
  );
};

export const unsetAdjusterDeployment = (id, eventId) => {
  return trackPromise(
    request({
      method: 'post',
      url: `/adjuster/${id}/undeploy/${eventId}`,
    }),
    'adjusters-profile-view'
  );
};

export const listAdjusterPaperwork = (id: any) => {
  return request({
    method: 'get',
    url: `/paperwork/adjuster/${id}`,
  });
};

export const listAdjusterLicenses = (id: any) => {
  return request({
    method: 'get',
    url: `/license/adjuster/${id}`,
  });
};

export const listAdjusterCertifications = (id: any) => {
  return request({
    method: 'get',
    url: `/certification/adjuster/${id}`,
  });
};

export const listAdjusterResumes = (id: any) => {
  return request({
    method: 'get',
    url: `/resume/adjuster/${id}`,
  });
};

export const listAdjusterLogs = (id: any, secondCall: boolean, params?: any) => {
  if (secondCall) {
    return request({
      method: 'get',
      url: `/log/${id}`,
      params,
    });
  } else {
    return trackPromise(
      request({
        method: 'get',
        url: `/log/${id}`,
        params,
      }),
      'logs-adjuster-list'
    );
  }
};

export const getFilteredFinances = (params?: any) => {
  return trackPromise(
    request({
      method: 'GET',
      url: `/finance/list`,
      params,
    }),
    'finance-adjuster-list'
  );
};
export const getInitialFinances = (params?: any) => {
  return request({
    method: 'GET',
    url: `/finance/list`,
    params,
  });
};
