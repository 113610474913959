import React from 'react';
import '../../assets/styles/Staff.css';

import '../../assets/styles/Staff.css';

function CreateAdminModal({ children, style }: { children?: any, style?: any }) {
  return (
    <div
      style={{
        color: 'red',
        fontSize: '13px',
        paddingTop: '5px',
        display: 'inline-block',
        ...style,
      }}
      className="error-label-custom"
    >
      {' '}
      {children}
    </div>
  );
}

export default CreateAdminModal;
