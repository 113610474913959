import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Grid, Pagination } from 'semantic-ui-react';
import '../../assets/styles/Staff.css';
import AdminListHeader from '../../components/admin-list/AdminListHeader';
import AdminListTable from '../../components/admin-list/AdminListTable';
import { adminActions } from '../../features/admin';
import services from '../../services';
import { IUser } from './../../models/user';

interface Props {
  user: IUser;
  getAdminList: (params: object) => void;
  resetAdminList: () => void;
  admins: object[];
  count: number;
}

interface State {
  tableData: object[];
  initialData: object[];
  sortOption: string;
  searchOption: string;
  rolesOption: string;
  totalPages: number;
  pagginationPage: number;
  showPagination: boolean;
  resetSelection: boolean;
  isLoading: boolean;
  isDeleted: boolean;
}

class AdminList extends React.Component<Props, State> {
  public state = {
    tableData: [],
    initialData: [],
    sortOption: 'createdAt',
    searchOption: '',
    rolesOption: 'all',
    totalPages: 0,
    pagginationPage: 0,
    showPagination: false,
    resetSelection: true,
    isLoading: false,
    isDeleted: false,
  };

  public filterAdmins = () => {
    const { sortOption, searchOption, rolesOption, pagginationPage, isDeleted } = this.state;
    this.setState({ tableData: [] }, () => {
      this.props.getAdminList({
        page: pagginationPage,
        pageSize: 10,
        sort: sortOption === 'createdAt' ? `createdAt,desc` : `${sortOption},asc`,
        search: searchOption,
        role: isDeleted ? undefined: rolesOption,
        showDeleted: isDeleted,
      });
    });
  };

  public setPagination = () => {
    if (!this.props.count) {
      this.setState({
        totalPages: 1,
        showPagination: false,
      });
      return;
    } else if (this.props.count !== 0) {
      this.setState({
        totalPages: Math.ceil(this.props.count / 10),
        showPagination: this.props.count > 10 ? true : false,
      });
    }
  };

  public handleSearch = (e: any, data: any) => {
    this.setState({ searchOption: data.value, pagginationPage: 0 }, () => this.filterAdmins());
  };

  public handleSort = (e: any, data: any) => {
    let sortOption = '';
    switch (data.value) {
      case 'Name':
        sortOption = 'firstName';
        break;
      case 'Creation Date':
        sortOption = 'createdAt';
        break;
      case 'Email':
        sortOption = 'email';
        break;
    }
    this.setState({ sortOption }, () => this.filterAdmins());
  };

  public handleRoles = (name: string) => {
    if (name === 'Deleted Admin') {
      this.setState({ rolesOption: name, pagginationPage: 0, isDeleted: true, }, () => this.filterAdmins());
    } else {
      this.setState({ rolesOption: name, pagginationPage: 0, isDeleted: false }, () => this.filterAdmins());
    }

  };

  public componentDidMount() {
    if (this.props.user.role !== 'Super Admin') {
      services.router.goto('/404');
      return;
    } else {
      this.setPagination();
      this.filterAdmins();
    }
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.user !== this.props.user) {
      if (this.props.user.role !== 'Super Admin') {
        services.router.goto('/404');
        return;
      }
    }
    if (prevProps.count !== this.props.count) {
      this.setPagination();
    }

    if (prevProps.admins !== this.props.admins) {
      this.setState({
        tableData: this.props.admins,
      });
    }
  }

  public handlePagination = (e: any, data: any) => {
    this.setState({ pagginationPage: data.activePage - 1 }, () => this.filterAdmins());
  };

  public render() {
    const { sortOption, searchOption, rolesOption } = this.state;

    return (
      <Grid stackable={true}>
        <Grid.Row>
          <AdminListHeader
            handleSearch={this.handleSearch}
            handleSort={this.handleSort}
            handleTab={this.handleRoles}
            fetchParams={{
              page: this.state.pagginationPage,
              pageSize: 10,
              sort: sortOption === 'createdAt' ? `createdAt,desc` : `${sortOption},asc`,
              search: searchOption,
              role: rolesOption,
            }}
          />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <AdminListTable
              handlePagination={this.handlePagination}
              activePage={this.state.pagginationPage}
              fetchAdmins={this.filterAdmins}
              user={this.props.user}
              resetSelection={this.state.rolesOption}
              tableData={this.state.tableData}
              resetAdmins={this.props.resetAdminList}
              fetchParams={{
                page: this.state.pagginationPage,
                pageSize: 10,
                sort: sortOption === 'createdAt' ? `createdAt,desc` : `${sortOption},asc`,
                search: searchOption,
                role: rolesOption,
              }}
            />
            {this.state.showPagination && (
              <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Pagination
                  totalPages={this.state.totalPages}
                  boundaryRange={1}
                  activePage={this.state.pagginationPage + 1}
                  // defaultActivePage={1}
                  ellipsisItem={null}
                  firstItem={null}
                  lastItem={null}
                  prevItem={
                    this.state.pagginationPage === 0
                      ? false
                      : {
                          'aria-label': 'Previous item',
                          content: 'PREVIOUS',
                        }
                  }
                  nextItem={
                    this.state.pagginationPage === this.state.totalPages - 1
                      ? false
                      : {
                          'aria-label': 'Next item',
                          content: 'NEXT',
                        }
                  }
                  siblingRange={1}
                  onPageChange={this.handlePagination}
                />
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  admins: state.admin.admins.adminList,
  count: state.admin.admins.count,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getAdminList: adminActions.getAdminList,
      resetAdminList: adminActions.resetAdminList,
    },
    dispatch
  );

export default connect<Props, State>(
  mapStateToProps,
  mapDispatchToProps
)(AdminList);
