import React from 'react';
import { Dropdown, Grid, Icon, Image, Menu, MenuItemProps, Search } from 'semantic-ui-react';
import searchIcon from '../../assets/images/Blue/icn-search-blue.svg';
import '../../assets/styles/Staff.css';
import CreateAdminModal from './CreateAdminModal';

interface Props {
  handleSearch: (e: any, data: any) => void;
  handleSort: (e: any, data: any) => void;
  handleTab: (name: string | undefined) => void;
  fetchParams: object;
}

interface State {
  activeItem: string | undefined;
}

const sortOptions = [
  {
    key: 'Creation Date',
    text: 'Creation Date',
    value: 'Creation Date',
  },
  {
    key: 'Name',
    text: 'Name',
    value: 'Name',
  },
  {
    key: 'Email',
    text: 'Email',
    value: 'Email',
  },
];

class AdminListHeader extends React.Component<Props, State> {
  public state = {
    activeItem: 'all',
  };

  public handleItemClick = (e: React.MouseEvent<HTMLAnchorElement>, { name }: MenuItemProps) => {
    this.props.handleTab(name);
    this.setState({ activeItem: name });
  };

  public render() {
    const { activeItem } = this.state;

    return (
      <div className="admin-list-header">
        <Menu text={true} className="tabs-menu">
          <Menu.Item name="all" active={activeItem === 'all'} onClick={this.handleItemClick}>
            All Roles
          </Menu.Item>
          <Menu.Item
            name="Super Admin"
            active={activeItem === 'Super Admin'}
            onClick={this.handleItemClick}
          >
            Super Admins
          </Menu.Item>
          <Menu.Item
            name="Office Admin"
            active={activeItem === 'Office Admin'}
            onClick={this.handleItemClick}
          >
            Office Admins
          </Menu.Item>
          <Menu.Item
            name="Read Only Office Admin"
            active={activeItem === 'Read Only Office Admin'}
            onClick={this.handleItemClick}
          >
            Read Only
          </Menu.Item>
          <Menu.Item
            name="Deleted Admin"
            active={activeItem === 'Deleted Admin'}
            onClick={this.handleItemClick}
          >
            Deleted
          </Menu.Item>
        </Menu>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span className="sort-field">
            Sort by:{' '}
            <Dropdown
              inline={true}
              options={sortOptions}
              defaultValue={'Creation Date'}
              onChange={this.props.handleSort}
            />
          </span>
          <Search
            input={{ iconPosition: 'left' }}
            icon={
              <Icon>
                <Image src={searchIcon} />
              </Icon>
            }
            placeholder="Search..."
            onSearchChange={this.props.handleSearch}
            showNoResults={false}
          />
          <CreateAdminModal fetchParams={this.props.fetchParams} />
        </div>
      </div>
    );
  }
}

export default AdminListHeader;
